import { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import {
  ActivityScrap_ActivityFragment,
  ActivityScrap_CurrentUserFragment,
} from 'generated/graphql'
import ActivityScrap from 'components/ActivityScrap'
import BookMarkIcon from 'svgIcons/BookMark'
import { getLimitNumText } from './module/getLimitNumText'

interface IFScrapBoxButtonProps {
  activity?: ActivityScrap_ActivityFragment
  currentUser?: ActivityScrap_CurrentUserFragment
  className?: string
}

const ScrapBoxButton: FC<IFScrapBoxButtonProps> = ({
  activity,
  currentUser,
  className,
}) => {
  const handleClickScrapStopPropagation =
    (scrapActivity: () => void) => (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      scrapActivity()
    }

  return (
    <ActivityScrap activity={activity} currentUser={currentUser}>
      {({ scrapActivity, scrapCount }) => {
        return (
          <StyledWrapper
            className={className}
            onClick={handleClickScrapStopPropagation(scrapActivity)}
            data-is-scrap={activity?.isScrapped}
          >
            <BookMarkIcon className="scrap-icon" isOn={activity?.isScrapped} />
            <div className="scrap-count">
              {getLimitNumText({ number: scrapCount ?? 0, limit: 999 })}
            </div>
          </StyledWrapper>
        )
      }}
    </ActivityScrap>
  )
}

export default ScrapBoxButton

const StyledWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 8px;
  gap: 6px;
  border-radius: 3px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  &[data-is-scrap='true'] {
    background-color: #01a0ff;
  }

  .scrap-icon {
    width: 7.5px;
    height: 10px;
  }
  .scrap-count {
    font-size: 12px;
    font-weight: 500;
  }
`
