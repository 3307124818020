import { FC, Fragment } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useGqlPopularChatRoomsDesktopQuery } from 'generated/graphql'
import PopularChatItemDesktop from './PopularChatItem.desktop'

gql`
  query gqlPopularChatRoomsDesktop(
    $limit: Int
    $filterBy: PopularChatRoomsFilterBy
  ) {
    popularChatRooms(limit: $limit, filterBy: $filterBy) {
      ...PopularChatItemDesktop_chatRoom
    }
  }
`

const PopularChatRoomsDesktop: FC = () => {
  const { data, loading } = useGqlPopularChatRoomsDesktopQuery({
    variables: { limit: 3 },
  })

  return (
    <StyledWrapper>
      <h2>실시간 인기 채팅방</h2>
      <div className="description">
        채팅방에서 기업의 채용 정보, 합격 스펙 등 실시간으로 정보를 공유하세요
      </div>
      <div className="chat-list">
        {(loading || !data) &&
          new Array(3)
            .fill('')
            .map((_, index) => (
              <div key={`chat-loading-${index}`} className="chat-loading" />
            ))}
        {!loading && (
          <Fragment>
            {data?.popularChatRooms.map((room) => (
              <PopularChatItemDesktop key={room.id} data={room} />
            ))}
          </Fragment>
        )}
      </div>
    </StyledWrapper>
  )
}

export default PopularChatRoomsDesktop

const StyledWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 90px;
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.8px;
  }
  .description {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.56px;
    margin: 5px 0 25px;
  }
  .chat-list {
    display: flex;
    gap: 25px;
    .chat-loading {
      width: 100%;
      height: 280px;
      background-color: #3333331c;
      border-radius: 5px;
    }
  }
`
