import { FC, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import {
  PopularChatItemDesktop_ChatRoomFragment,
  useCurrentUserQuery,
} from 'generated/graphql'
import Button from '@app.components/Button'
import ActivityChatMessages from '@app.feature/activity/activity.detail/desktop/components/ActivityChat/ActivityChatMessage'
import { useChattingContext } from '@app.feature/chatting/chattingContext/module/ChattingContext'
import { formatBadgeTextPerActivityType } from '@app.feature/chatting/chattingContext/module/formatBadgeTextPerActivityType'
import LoginAlert from 'components/LoginAlert'

gql`
  fragment PopularChatItemDesktop_chatRoom on ChatRoom {
    id
    name
    activityTypeId
    memberCount
    isActive
    isMentorRecentChatMessageSentInPopularChatRoom
    isManagerRecentChatMessageSentInPopularChatRoom
  }
`

interface IFPopularChatItemDesktopProps {
  data: PopularChatItemDesktop_ChatRoomFragment
  className?: string
}

const PopularChatItemDesktop: FC<IFPopularChatItemDesktopProps> = ({
  data,
  className,
}) => {
  const { openChatting } = useChattingContext()
  const [loginModalOpened, setLoginModalOpened] = useState(false)
  const { data: currentUserData } = useCurrentUserQuery()

  const handleClickChatBtn = () => {
    if (!currentUserData?.currentUser) {
      return setLoginModalOpened(true)
    }

    openChatting({
      type: 'reportChatOpenChatRoom',
      data: { chatRoomId: data.id },
    })
  }

  const renderChatBadge = () => {
    if (data.isManagerRecentChatMessageSentInPopularChatRoom) {
      return (
        <Fragment>
          <Image
            src="/static/images/chat/chat-active-manager.svg"
            width={16}
            height={16}
            alt={`${formatBadgeTextPerActivityType(data.activityTypeId)} 뱃지`}
          />
          <div className="chat-manager-active">{`${formatBadgeTextPerActivityType(
            data.activityTypeId,
          )}`}</div>
        </Fragment>
      )
    }

    if (data.isMentorRecentChatMessageSentInPopularChatRoom) {
      return (
        <Fragment>
          <Image
            src="/static/images/chat/chat-active.svg"
            width={16}
            height={16}
            alt="현직자 대화중 뱃지"
          />
          <div className="chat-active">현직자 대화중</div>
        </Fragment>
      )
    }

    if (data.isActive) {
      return (
        <Fragment>
          <div className="chat-active">지금 대화중</div>
        </Fragment>
      )
    }
  }

  return (
    <StyledWrapper className={className}>
      <div className="chat-header">
        <div className="chat-info">
          <div className="chat-name">{data.name}</div>
          <div className="chat-member">
            {renderChatBadge()}
            <span className="member-count">{data.memberCount}명</span>
          </div>
        </div>
        <Button
          className="chat-btn"
          color="transparent"
          onClick={handleClickChatBtn}
        >
          채팅방 참여하기
        </Button>
      </div>
      <ActivityChatMessages chatRoomId={data.id} className="chat-messages" />
      <LoginAlert
        DialogProps={{ open: loginModalOpened }}
        onRequestClose={() => setLoginModalOpened(false)}
        chatRoomId={data.id}
      />
    </StyledWrapper>
  )
}

export default PopularChatItemDesktop

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 350px;
    height: 285px;
    border: solid 1px #ddd;
    border-radius: 5px;

    .chat-header {
      display: flex;
      align-items: center;
      gap: 19px;
      padding: 17px 20px 15px;
      border-bottom: solid 1px #ddd;
      margin-bottom: 10px;

      .chat-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1px;
        .chat-name {
          max-width: 170px;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.33;
          letter-spacing: -0.6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .chat-member {
          display: flex;
          gap: 5px;
          font-size: 12px;
          line-height: 1.67;
          letter-spacing: -0.48px;
          color: #666;
          align-items: center;
          .chat-active {
            color: ${theme.color.primary_2};
          }
          .chat-manager-active {
            color: #30b243;
          }
          .chat-active,
          .chat-manager-active {
            position: relative;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: -0.48px;
            margin-right: 8px;
            &:after {
              content: '';
              position: absolute;
              top: 6px;
              left: 100%;
              height: 60%;
              width: 1px;
              margin-left: 6px;
              background-color: #dddddd;
            }
          }
          p {
            font-size: 1px;
            padding-top: 1px;
          }
        }
      }
      .chat-btn {
        height: 35px;
        padding: 0 15px;
        flex-shrink: 0;
        color: ${theme.color.primary_2};
        font-size: 12px;
        font-weight: 500;
        line-height: 2.5;
        letter-spacing: -0.48px;
        border: solid 1px ${theme.color.primary_2};
        :hover {
          background-color: transparent;
        }
      }
    }
    .chat-messages {
      background-color: ${theme.background.bg_8};
    }
  `}
`
