import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useGqlPopularChatRoomsMobileQuery } from 'generated/graphql'
import PopularChatItemMobile from './PopularChatItem.mobile'

gql`
  query gqlPopularChatRoomsMobile(
    $limit: Int
    $offset: Int
    $filterBy: PopularChatRoomsFilterBy
  ) {
    popularChatRooms(limit: $limit, offset: $offset, filterBy: $filterBy) {
      ...PopularChatItemMobile_chatRoom
    }
  }
`

const PopularChatRoomsMobile: FC = () => {
  const { data } = useGqlPopularChatRoomsMobileQuery({
    variables: { limit: 4 },
  })

  return (
    <StyledWrapper>
      <h2>실시간 인기 채팅방</h2>
      <div className="description">
        채팅방에서 기업의 채용 정보, 합격 스펙 등 <br /> 실시간으로 정보를
        공유하세요
      </div>
      <div className="chat-list">
        {data?.popularChatRooms.map((room) => (
          <PopularChatItemMobile key={room.id} data={room} />
        ))}
      </div>
    </StyledWrapper>
  )
}

export default PopularChatRoomsMobile

const StyledWrapper = styled.div`
  padding: 20px 0;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -0.64px;
    padding: 0 20px;
  }
  .description {
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.48px;
    margin: 5px 0 15px;
    padding: 0 20px;
  }
  .chat-list {
    padding-left: 20px;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
